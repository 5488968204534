import { ListAppResult } from '@backend/types/Loan';
import SSCError from '@backend/types/SSCError';
import { Close, FilterList, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { InternalLoanStatus } from '../../assets/const';
import oracleLogo from '../../assets/img/ic_oracle1.png';
import { DataKeyVal } from '../../components/DataKeyVal';
import { DataChip } from '../../components/Underwriting/DataChip';
import { OracleInsight } from '../../components/Underwriting/OracleInsight';
import { isUWOrBDUser } from '../../helpers/auth';
import {
  avgDscrToLabel,
  fccrToLabel,
  minDscrToLabel,
  toCurrency,
} from '../../helpers/converters';
import useDebouncedValue from '../../hooks/useDebouncedValue';
import { ErrorPage } from '../ErrorPage';

export const ApplicationList = () => {
  interface ApplicationRecord extends ListAppResult {
    fccr: number;
    minDscr?: number;
    avgDscr?: number;
    totalObligations: number;
    hasOracleInsights: boolean;
  }
  const [apps, setApps] = useState([] as ApplicationRecord[]);
  const [loadError, setLoadError] = useState({} as SSCError);
  const [openModal, setOpenModal] = useState(false);
  const [oracleModalOpen, setOracleModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(<></>);
  const [oracleModalContent, setOracleModalContent] = useState(<></>);

  // Picklist values for Solar_System_Power_Company_Type__c and the mapping of the
  // data chip styles and the labels
  const businessTypeMap = {
    'Operating Company': { className: 'data-chip-green-light', label: 'OpCo' },
    'Property Company': { className: 'data-chip-blue-light', label: 'PropCo' },
    'Non-Profit': { className: 'data-chip-orange-light', label: 'Non-Profit' },
    HOA: { className: 'data-chip-orange-light', label: 'HOA' },
    // Add more types as needed
  };

  const tabIndexMap = {
    WITH_SUNSTONE: 0,
    WITH_BORROWER: 1,
    CLOSED: 2,
    SEARCH_RESULTS: 3,
  };

  const enum DateFilterKeys {
    LAST_30 = 'l30',
    LAST_90 = 'l90',
    THIS_YEAR = 'ty',
    DATE_RANGE = 'dr',
  }
  const [viewFilters, setViewFilters] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebouncedValue(searchTerm, 200);
  const [statusFilter, setStatusFilter] = useState(new Set());
  const [fccrBucket, setFccrBucket] = useState(new Set());
  const [minDscrBucket, setMinDscrBucket] = useState(new Set());
  const [avgDscrBucket, setAvgDscrBucket] = useState(new Set());

  const submitDates = [
    { key: DateFilterKeys.LAST_30, value: 'Last 30 days' },
    { key: DateFilterKeys.LAST_90, value: 'Last 90 days' },
    { key: DateFilterKeys.THIS_YEAR, value: 'This year' },
  ];

  const [dateRange, setDateRange] = useState({ start: '', end: '' });

  const [submitFilter, setSubmitFilter] = useState('');
  const [filterIndex, setFilterIndex] = useState(0);
  const filterCategories = ['FCCR', 'Min. DSCR', 'Avg. DSCR'];

  const fccrStates = [
    { label: 'Good (≥1.1)', key: 'good' },
    { label: 'Okay (≥1.0 and <1.1)', key: 'okay' },
    { label: 'Bad (<1.0)', key: 'bad' },
  ];

  const minDscrStates = [
    { label: 'Good (>1.0)', key: 'good' },
    { label: 'Okay (≥0.85 and <1.0)', key: 'okay' },
    { label: 'Bad (<0.85)', key: 'bad' },
  ];

  const avgDscrStates = [
    { label: 'Good (>1.15)', key: 'good' },
    { label: 'Okay (≥1.0 and <1.15)', key: 'okay' },
    { label: 'Bad (<1.0)', key: 'bad' },
  ];

  const [crbLoans, setCrbLoans] = useState(false);
  const [testLoans, setTestLoans] = useState(false);
  const [pgLoans, setPgLoans] = useState(false);

  const [tabIndex, setTabIndex] = useState(tabIndexMap.WITH_SUNSTONE);

  const handleFccrClicked = (record: ApplicationRecord) => {
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      minWidth: 400,
      bgcolor: 'background.paper',
      border: '1px solid lightgrey',
      boxShadow: 24,
      p: 8,
      borderRadius: '5px',
    };
    let totalFCCR = 0;
    for (const year of Object.keys(record.financials)) {
      totalFCCR +=
        record.financials[year].find((fin) => fin.dataName === 'FCCR')?.value ??
        0;
    }
    const averageFCCR = totalFCCR / Object.keys(record.financials).length;

    setModalContent(
      <Box sx={style} className="flex-column flex-gap-1">
        <IconButton
          onClick={() => setOpenModal(false)}
          sx={{
            position: 'absolute',
            top: 5,
            right: 5,
          }}
        >
          <Close />
        </IconButton>
        <Box className="flex-row-space-between">
          <Box>
            <Typography variant="caption">Application ID</Typography>
            <Typography variant="body2">{record.Name}</Typography>
          </Box>
          <Box>
            <Typography variant="caption">Borrower Business Name</Typography>
            <Typography variant="body2">
              {record.Borrower_Business_Name__c}
            </Typography>
          </Box>
          <Box>
            <Typography variant="caption">Average FCCR</Typography>
            <DataChip value={averageFCCR} low={1.0} okay={1.1} />
          </Box>
        </Box>
        <Box>
          <Typography variant="body2">
            Total Obligation: {toCurrency(record.totalObligations)}
          </Typography>
        </Box>

        {Object.keys(record.financials).length > 0 && (
          <Typography variant="h5">FCCR Score History</Typography>
        )}

        {Object.keys(record.financials).map((year) => {
          return (
            <Box
              sx={{
                borderRadius: '5px',
                border: '1px solid lightgrey',
                p: '1rem',
              }}
              key={year}
            >
              <Typography variant="h6">{year}</Typography>
              <Box className="flex-row-space-between">
                <Typography variant="body1">
                  EBITDA:{' '}
                  {toCurrency(
                    record.financials[year].filter(
                      (fins) => fins.dataName === 'EBITDA',
                    )[0]?.value ?? 0,
                  )}
                </Typography>
                {record.financials[year].some(
                  (fins) => fins.dataName === 'FCCR',
                ) && (
                  <Box className="flex-row-space-between flex-gap-0_5">
                    <Typography>FCCR:</Typography>
                    <DataChip
                      value={
                        record.financials[year].filter(
                          (fins) => fins.dataName === 'FCCR',
                        )[0].value
                      }
                      low={1.0}
                      okay={1.1}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          );
        })}
      </Box>,
    );
    setOpenModal(true);
  };

  const handleOracleInsightsClicked = async (applicationId: string) => {
    const oracleResponse = await axios.get(
      `/api/v1/oracle/insights?appId=${applicationId}`,
    );
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 900,
      height: 500,
      bgcolor: 'background.paper',
      border: '1px solid lightgrey',
      boxShadow: 24,
      p: 4,
      borderRadius: '5px',
    };
    setOracleModalContent(
      <Box sx={style} className="flex-column flex-gap-1">
        <Box
            sx={{
              overflow: 'auto',
              border: '1px solid lightgrey',
              borderRadius: '4px',
            }}
          >
        {Object.keys(oracleResponse.data).length > 0 ? (
          oracleResponse.data.map((insight) => (
          <Grid item xs={12} md={6} xl={4} key={insight.id}>
            <OracleInsight oracleInsight={insight} />
          </Grid>
          ))
        ) : (
          <Typography variant="body2">
            No insights processed for this application.
          </Typography>
          )}
        </Box>
      </Box>,
    );
    setOracleModalOpen(true);
  };

  const handleDSCRClicked = (
    applicationId,
    appName: string,
    businessName: string,
    systemState: string,
    year1Savings: number,
    systemSize: number,
  ) => {
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 900,
      height: 500,
      bgcolor: 'background.paper',
      border: '1px solid lightgrey',
      boxShadow: 24,
      p: 4,
      borderRadius: '5px',
    };
    axios.get(`/api/v1/uw/solar-econ/${applicationId}`).then((result) => {
      const headers = [
        'Year',
        'Annual Loan Payment',
        'REC Incentive',
        'Total Solar Savings',
        'Solar DSCR',
      ];
      setModalContent(
        <Box sx={style} className="flex-column flex-gap-1">
          <IconButton
            onClick={() => setOpenModal(false)}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
            }}
          >
            <Close />
          </IconButton>
          <Box className={'flex-row-start'}>
            <DataKeyVal label="Loan ID" value={appName} />
            <DataKeyVal label="Borrower Business Name" value={businessName} />
            <DataKeyVal label="Solar System State" value={systemState} />
            <DataKeyVal
              label="Year 1 Total Savings"
              value={toCurrency(year1Savings)}
            />
            <DataKeyVal
              label="System Size (kW)"
              value={systemSize.toFixed(2)}
            />
          </Box>
          <Typography variant="h6">Solar DSCR</Typography>
          <Box
            sx={{
              overflow: 'auto',
              border: '1px solid lightgrey',
              borderRadius: '4px',
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  {headers.map((header, i) => (
                    <TableCell align="center" key={i}>
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {result.data.map((solarEcon: any) => (
                  <TableRow key={solarEcon.id}>
                    <TableCell align="right">{solarEcon.year}</TableCell>
                    <TableCell align="right">
                      {toCurrency(solarEcon.annual_payment)}
                    </TableCell>
                    <TableCell align="right">
                      {toCurrency(solarEcon.rec_incentive)}
                    </TableCell>
                    <TableCell align="right">
                      {toCurrency(solarEcon.total_solar_savings)}
                    </TableCell>
                    <TableCell>
                      <Box sx={{ width: '5rem' }}>
                        <DataChip
                          low={1}
                          okay={1}
                          value={solarEcon.solar_dscr}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Box>,
      );
      setOpenModal(true);
    });
  };

  const columns = [
    {
      field: 'Name',
      headerName: 'App ID',
      flex: 0.5,
      renderCell: (params: any) => (
        <Link
          to={`/loans/application/${params.row.id}${
            isUWOrBDUser() ? '/documents' : ''
          }`}
        >
          {params.row.Name}
        </Link>
      ),
    },
    {
      field: 'Borrower_Business_Name__c',
      headerName: 'Borrower Business Name',
      flex: 1,
    },
    {
      field: 'Internal_Status__c',
      headerName: 'Internal Status',
      flex: 1,
    },
    {
      field: 'loan_amount__c',
      headerName: 'Loan Amount',
      flex: 1,
      valueGetter: (params: any) => toCurrency(params.value),
    },
    {
      field: 'Solar_System_Power_Company_Type__c',
      headerName: 'Business Type',
      description: 'Business Type',
      flex: 0.5,
      renderCell: (params: any) => (
        <Box>
          {params.row.Solar_System_Power_Company_Type__c &&
            businessTypeMap[params.row.Solar_System_Power_Company_Type__c] && (
              <Box
                maxWidth={200}
                className={
                  'data-chip ' +
                  businessTypeMap[params.row.Solar_System_Power_Company_Type__c]
                    .className
                }
              >
                <Typography>
                  {
                    businessTypeMap[
                      params.row.Solar_System_Power_Company_Type__c
                    ].label
                  }
                </Typography>
              </Box>
            )}
        </Box>
      ),
    },
    {
      field: 'loanType',
      headerName: 'Express',
      description: 'Whether it is an Express loan type - empty for no',
      renderCell: (params: any) => (
        <Box>
          {params.row.Is_Personal_Guarantor__c === 'Yes' && (
            <Box maxWidth={200} className={`data-chip data-chip-grey`}>
              <Typography>PG</Typography>
            </Box>
          )}
        </Box>
      ),
    },
    {
      field: 'avgDscr',
      headerName: 'Avg. DSCR',
      description: 'Average Solar DSCR based on a 20 year period',
      renderCell: (params: any) => (
        <Box
          sx={{
            cursor:
              params.row.avgDscr > Number.NEGATIVE_INFINITY ? 'pointer' : '',
          }}
          onClick={() => {
            if (params.row.avgDscr > Number.NEGATIVE_INFINITY)
              handleDSCRClicked(
                params.row.id,
                params.row.Name,
                params.row.Borrower_Business_Name__c,
                params.row.Sys_Prop_Address_State__c,
                params.row.Year_1_Total_Solar_Savings__c,
                params.row.System_Size_in_kWs__c,
              );
          }}
        >
          <DataChip value={params.row.avgDscr} low={0.8} okay={0.8} />
        </Box>
      ),
    },
    {
      field: 'minDscr',
      headerName: 'Min. DSCR',
      description: 'Minimum value for Solar DSCR over a 20 year period',
      renderCell: (params: any) => (
        <Box
          sx={{
            cursor:
              params.row.minDscr > Number.NEGATIVE_INFINITY ? 'pointer' : '',
          }}
          onClick={() => {
            if (params.row.minDscr > Number.NEGATIVE_INFINITY)
              handleDSCRClicked(
                params.row.id,
                params.row.Name,
                params.row.Borrower_Business_Name__c,
                params.row.Sys_Prop_Address_State__c,
                params.row.Year_1_Total_Solar_Savings__c,
                params.row.System_Size_in_kWs__c,
              );
          }}
        >
          <DataChip value={params.row.minDscr} low={0.6} okay={0.6} />
        </Box>
      ),
    },
    {
      field: 'fccr',
      headerName: 'FCCR',
      width: 120,
      description: `${
        moment().year() - 1
      } FCCR. Click the FCCR to view the loan application's FCCR for the last 3 years.`,
      renderCell: (params: any) => (
        <Box
          sx={{ cursor: 'pointer' }}
          onClick={() => handleFccrClicked(params.row)}
        >
          <DataChip value={params.row.fccr} low={1.0} okay={1.1} />
        </Box>
      ),
    },
    {
      field: 'oracleInsights',
      headerName: 'Insights',
      width: 80,
      description: `Oracle Insights. Click to view the loan application's Oracle Insights.`,
      renderCell: (params: any) => (
        params.row.hasOracleInsights ? (
          <Box
            sx={{
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
            onClick={() => handleOracleInsightsClicked(params.row.id)}
          >
            <img src={oracleLogo} style={{ width: '25px' }} />
          </Box>
        ) : null
      ),
    },

    {
      field: 'CreatedDate',
      headerName: 'Created Date',
      renderCell: (params) => (
        <Typography variant="body2">
          {params.row.CreatedDate.split('T')[0]}
        </Typography>
      ),
    },
  ];

  useEffect(() => {
    axios
      .get<ListAppResult[]>('/api/v1/loans/apps')
      .then((res) => {
        const records = res.data.map((record) => {
          const LY = Object.keys(record.financials).pop() as string;
          const fccrRecord = record.financials[LY]?.filter(
            (fins) => fins?.dataName === 'FCCR',
          ).at(0);
          const fccr = fccrRecord?.value ?? Number.NEGATIVE_INFINITY;
          const totalObligations = record.obligations.reduce(
            (acc, obj) => acc + obj.balance,
            0,
          );
          return {
            ...record,
            id: record.Id,
            fccr,
            minDscr: record.minDscr,
            avgDscr: record.avgDscr,
            totalObligations,
            hasOracleInsights: record.hasOracleInsights,
          };
        });
        setApps(records);
        setSubmitFilter('');
      })
      .catch((err) => {
        console.error(err);
        setLoadError({
          title: 'Error Fetching Loan Applications',
          message:
            err.response?.data?.message ??
            'An error occurred while trying to fetch loan applications.',
          status: err.response?.status,
        });
      });

    // Setup search if that's what user is searching
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    if (params.get('search') !== null) {
      setSearchTerm(params.get('search') ?? '');
      setTabIndex(tabIndexMap.SEARCH_RESULTS);
    }
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const clearSearch = () => {
    setSearchTerm('');
    setTabIndex(tabIndexMap.WITH_SUNSTONE);
  };

  const applySearchTerm = (apps: ApplicationRecord[]) => {
    return apps.filter((app) => {
      if (!debouncedSearchTerm) return true;
      const query = debouncedSearchTerm.toLowerCase();

      return (
        app.Name.toLowerCase().includes(query) ||
        app.Borrower_Business_Name__c.toLowerCase().includes(query)
      );
    });
  };

  const applyFilters = (apps: ApplicationRecord[]) => {
    return apps
      .filter((app) => (crbLoans ? app.CRB_Application_ID__c : true))
      .filter((app) =>
        testLoans
          ? app.Borrower_Business_Name__c.toLocaleLowerCase().includes('test')
          : !app.Borrower_Business_Name__c.toLocaleLowerCase().includes('test'),
      )
      .filter((app) =>
        pgLoans ? app.Is_Personal_Guarantor__c === 'Yes' : true,
      )
      .filter(
        (app) =>
          statusFilter.size === 0 || statusFilter.has(app.Internal_Status__c),
      )
      .filter(
        (app) => fccrBucket.size === 0 || fccrBucket.has(fccrToLabel(app.fccr)),
      )
      .filter(
        (app) =>
          minDscrBucket.size === 0 ||
          minDscrBucket.has(minDscrToLabel(app.minDscr!)),
      )
      .filter(
        (app) =>
          avgDscrBucket.size === 0 ||
          avgDscrBucket.has(avgDscrToLabel(app.avgDscr!)),
      )
      .filter((app) => {
        if (submitFilter === '') return true;
        const days =
          submitFilter === DateFilterKeys.LAST_30
            ? 30
            : submitFilter === DateFilterKeys.LAST_90
              ? 90
              : submitFilter === DateFilterKeys.THIS_YEAR
                ? 365
                : 0;
        if (submitFilter === DateFilterKeys.DATE_RANGE) {
          return moment(app.CreatedDate).isBetween(
            moment(dateRange.start),
            moment(dateRange.end),
          );
        }
        return moment(app.CreatedDate).isBetween(
          moment().subtract(days, 'days'),
          moment(),
        );
      });
  };

  const applyFiltersForStatuses = (statuses: string[]) => {
    return applyFilters(apps).filter((app) =>
      statuses.includes(app.Internal_Status__c),
    );
  };

  if (loadError.title) {
    return (
      <ErrorPage
        title={loadError.title}
        message={loadError.message}
        status={loadError.status}
      />
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <Box className="flex-row-space-between">
        <Typography variant="h6">Loan Applications</Typography>
        <Box className="flex-row-space-between flex-gap-1">
          <Button
            variant="lightweight"
            color="neutral"
            onClick={() => {
              setSubmitFilter('');
              setDateRange({ start: '', end: '' });
              setSearchTerm('');
              setFccrBucket(new Set());
              setMinDscrBucket(new Set());
              setAvgDscrBucket(new Set());
              setStatusFilter(new Set<string>());
              setCrbLoans(false);
              setTestLoans(false);
              setPgLoans(false);
            }}
          >
            Clear filters
          </Button>
          <Button
            variant="neutral"
            startIcon={<FilterList />}
            onClick={() => setViewFilters(!viewFilters)}
          >
            {`Filter${
              statusFilter.size > 0 ||
              fccrBucket.size > 0 ||
              minDscrBucket.size > 0 ||
              avgDscrBucket.size > 0 ||
              submitFilter !== '' ||
              crbLoans ||
              testLoans ||
              pgLoans
                ? ` (${
                    statusFilter.size +
                    fccrBucket.size +
                    minDscrBucket.size +
                    avgDscrBucket.size +
                    (submitFilter !== '' ? 1 : 0) +
                    (crbLoans ? 1 : 0) +
                    (testLoans ? 1 : 0) +
                    (pgLoans ? 1 : 0)
                  })`
                : ''
            }`}
          </Button>

          <TextField
            label="Search"
            variant="outlined"
            sx={{
              bgcolor: 'white',
              minWidth: '40%',
              borderRadius: '5px',
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
            size="small"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                const target = e.target as HTMLInputElement;
                if (target.value === '') {
                  clearSearch();
                } else {
                  setTabIndex(tabIndexMap.SEARCH_RESULTS);
                  setSearchTerm(target.value);
                }
              }
            }}
          ></TextField>
        </Box>
      </Box>
      <Collapse in={viewFilters}>
        <Box className="filter-box">
          <Box className="flex-column flex-gap-0_5">
            <Typography variant="subtitle2">Internal Status</Typography>
            <Box
              className="flex-column"
              sx={{
                maxHeight: '12rem',
                overflow: 'scroll',
                scrollBehavior: 'auto',
                bgcolor: 'white',
                p: '1rem',
                borderRadius: '5px',
              }}
            >
              {Object.keys(InternalLoanStatus).map((key) => (
                <FormControlLabel
                  key={key}
                  label={
                    <Typography variant="caption">
                      {InternalLoanStatus[key]}
                    </Typography>
                  }
                  control={
                    <Checkbox
                      checked={statusFilter.has(InternalLoanStatus[key])}
                      onChange={(e) => {
                        if (!statusFilter.has(key) && e.target.checked) {
                          setStatusFilter(
                            (old) => new Set([...old, InternalLoanStatus[key]]),
                          );
                        } else {
                          statusFilter.delete(InternalLoanStatus[key]);
                          setStatusFilter((old) => new Set([...statusFilter]));
                        }
                      }}
                    />
                  }
                />
              ))}
            </Box>
          </Box>

          <FormControl sx={{ width: '20em' }} size="small">
            <InputLabel id="category-label">Category</InputLabel>
            <Select
              label="Category"
              labelId="category-label"
              value={filterIndex}
              onChange={(e) => setFilterIndex(Number(e.target.value))}
            >
              {filterCategories.map((label, i) => (
                <MenuItem key={label + i} value={i}>
                  {label}
                </MenuItem>
              ))}
            </Select>
            <FormGroup>
              {filterIndex === 0 &&
                fccrStates.map((dscrState) => (
                  <FormControlLabel
                    key={dscrState.key}
                    label={dscrState.label}
                    control={
                      <Checkbox
                        checked={[...fccrBucket].includes(dscrState.key)}
                        onChange={(e) => {
                          if (e.target.checked)
                            setFccrBucket((bucket) => {
                              bucket.add(dscrState.key);
                              return new Set([...bucket]);
                            });
                          else
                            setFccrBucket((bucket) => {
                              bucket.delete(dscrState.key);
                              return new Set([...bucket]);
                            });
                        }}
                      />
                    }
                  />
                ))}

              {filterIndex === 1 &&
                minDscrStates.map((dscrState) => (
                  <FormControlLabel
                    key={dscrState.key}
                    label={dscrState.label}
                    control={
                      <Checkbox
                        checked={[...minDscrBucket].includes(dscrState.key)}
                        onChange={(e) => {
                          if (e.target.checked)
                            setMinDscrBucket((bucket) => {
                              bucket.add(dscrState.key);
                              return new Set([...bucket]);
                            });
                          else
                            setMinDscrBucket((bucket) => {
                              bucket.delete(dscrState.key);
                              return new Set([...bucket]);
                            });
                        }}
                      />
                    }
                  />
                ))}
              {filterIndex === 2 &&
                avgDscrStates.map((dscrState) => (
                  <FormControlLabel
                    key={dscrState.key}
                    label={dscrState.label}
                    control={
                      <Checkbox
                        checked={[...avgDscrBucket].includes(dscrState.key)}
                        onChange={(e) => {
                          if (e.target.checked)
                            setAvgDscrBucket((bucket) => {
                              bucket.add(dscrState.key);
                              return new Set([...bucket]);
                            });
                          else
                            setAvgDscrBucket((bucket) => {
                              bucket.delete(dscrState.key);
                              return new Set([...bucket]);
                            });
                        }}
                      />
                    }
                  />
                ))}
            </FormGroup>
          </FormControl>

          <Box className="flex-column">
            <Typography variant="subtitle2">Bank Loans</Typography>
            <FormControlLabel
              label={<Typography variant="caption">CRB Loans</Typography>}
              control={
                <Checkbox
                  checked={crbLoans}
                  onChange={(e) => setCrbLoans(e.target.checked)}
                />
              }
            />
          </Box>
          <Box className="flex-column" width={150}>
            <Typography variant="subtitle2">Created</Typography>
            <RadioGroup>
              {submitDates.map((submit) => (
                <FormControlLabel
                  key={submit.key}
                  label={
                    <Typography variant="caption">{submit.value}</Typography>
                  }
                  control={
                    <Radio
                      value={submit.key}
                      checked={submitFilter === submit.key}
                      onClick={(e) => {
                        if (
                          (e.target as HTMLInputElement).value === submitFilter
                        )
                          setSubmitFilter('');
                        else setSubmitFilter(submit.key);
                      }}
                    />
                  }
                />
              ))}

              <FormControlLabel
                label={<Typography variant="caption">Date range</Typography>}
                control={
                  <Radio
                    value={DateFilterKeys.DATE_RANGE}
                    checked={submitFilter === DateFilterKeys.DATE_RANGE}
                    onClick={(e) => {
                      if ((e.target as HTMLInputElement).value === submitFilter)
                        setSubmitFilter('');
                      else setSubmitFilter(DateFilterKeys.DATE_RANGE);
                    }}
                  />
                }
              />
            </RadioGroup>
            <Collapse in={submitFilter === DateFilterKeys.DATE_RANGE}>
              <Box className="flex-row-space-around flex-gap-0_5">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={dateRange.start}
                    sx={{ bgcolor: 'white', fontSize: '0.8rem' }}
                    label="MM/DD/YYYY"
                    onChange={(newDate) => {
                      setDateRange({
                        start: newDate?.toString() ?? '',
                        end: dateRange.end,
                      });
                    }}
                  />
                  <Typography variant="body2">to</Typography>
                  <DatePicker
                    value={dateRange.end}
                    sx={{ bgcolor: 'white' }}
                    label="MM/DD/YYYY"
                    onChange={(newDate) => {
                      setDateRange({
                        start: dateRange.start,
                        end: newDate?.toString() ?? '',
                      });
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Collapse>
          </Box>
          <Box className="flex-column">
            <Typography variant="subtitle2">Loan Type</Typography>
            <FormControlLabel
              label={<Typography variant="caption">Test Loans</Typography>}
              control={
                <Checkbox
                  checked={testLoans}
                  onChange={(e) => setTestLoans(e.target.checked)}
                />
              }
            />
            <FormControlLabel
              label={<Typography variant="caption">PG Loans</Typography>}
              control={
                <Checkbox
                  checked={pgLoans}
                  onChange={(e) => setPgLoans(e.target.checked)}
                />
              }
            />
          </Box>
        </Box>
      </Collapse>
      <Paper variant="outlined" sx={{ p: '1rem' }}>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab label="With Sunstone" />
          <Tab label="With Borrower" />
          <Tab label="Closed" />
          {searchTerm && (
            <Tab
              label={
                <span>
                  {`Search Results (${searchTerm})`}{' '}
                  <IconButton
                    size="small"
                    component="span"
                    onClick={() => {
                      clearSearch();
                    }}
                  >
                    <Close />
                  </IconButton>
                </span>
              }
            />
          )}
        </Tabs>

        {/* Choose the data the grid should show with the appropriate filters */}
        {tabIndex === tabIndexMap.WITH_SUNSTONE && (
          <DataGrid
            rows={applyFiltersForStatuses([
              InternalLoanStatus.UW_IN_PROGRESS,
              InternalLoanStatus.DOCUMENTS_PENDING,
              InternalLoanStatus.PENDING_INSTALLER,
              InternalLoanStatus.APPLICATION_COMPLETE,
              InternalLoanStatus.UW_IN_PROGRESS,
              InternalLoanStatus.UW_COMPLETE,
              InternalLoanStatus.READY_FOR_BANK,
              InternalLoanStatus.COMPLIANCE_PENDING,
              InternalLoanStatus.COMPLIANCE_FAIL,
              InternalLoanStatus.COMPLIANCE_PASS,
              InternalLoanStatus.AGREEMENTS_PENDING,
              InternalLoanStatus.AGREEMENTS_EXECUTED,
              InternalLoanStatus.AGREEMENT_COMPLETE,
              InternalLoanStatus.READY_TO_FUND,
              InternalLoanStatus.LOAN_FUNDED,
              InternalLoanStatus.UNDERWRITING_REFRESH_IN_PROGRESS,
              InternalLoanStatus.PENDING_CHANGE_REQUEST,
            ])}
            columns={columns}
            autoHeight={true}
          />
        )}
        {tabIndex === tabIndexMap.WITH_BORROWER && (
          <DataGrid
            rows={applyFiltersForStatuses([
              InternalLoanStatus.APPLICATION_CREATED,
              InternalLoanStatus.APPLICATION_PENDING,
              InternalLoanStatus.PENDING_BORROWER,
            ])}
            columns={columns}
            autoHeight={true}
          />
        )}

        {tabIndex === tabIndexMap.CLOSED && (
          <DataGrid
            rows={applyFiltersForStatuses([InternalLoanStatus.CLOSED])}
            columns={columns}
            autoHeight={true}
          />
        )}

        {/* Search Results Tab */}
        {tabIndex === tabIndexMap.SEARCH_RESULTS && (
          <DataGrid
            rows={applySearchTerm(apps)}
            columns={columns}
            autoHeight={true}
          />
        )}
      </Paper>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        {modalContent}
      </Modal>
      <Modal open={oracleModalOpen} onClose={() => setOracleModalOpen(false)}>
        {oracleModalContent}
      </Modal>
    </Box>
  );
};