export enum envKeys {
  SF_USERNAME = 'SF_USERNAME',
  SF_PASSWORD = 'SF_PASSWORD',
  SF_TOKEN = 'SF_TOKEN',
  CRB_ID = 'CRB_ID',
  CRB_SECRET = 'CRB_SECRET',
  LEXIS_NEXIS_USER = 'LEXIS_NEXIS_USER',
  LEXIS_NEXIS_PASSWORD = 'LEXIS_NEXIS_PASSWORD',
  EQUIFAX_CLIENT_ID = 'EQUIFAX_CLIENT_ID',
  EQUIFAX_CLIENT_SECRET = 'EQUIFAX_CLIENT_SECRET',
  EQUIFAX_MEMBER_NUMBER = 'EQUIFAX_MEMBER_NUMBER',
  EQUIFAX_SECURITY_CODE = 'EQUIFAX_SECURITY_CODE',
  DNB_CLIENT_ID = 'DNB_CLIENT_ID',
  DNB_CLIENT_SECRET = 'DNB_CLIENT_SECRET',
  COOKIE_SECRET = 'COOKIE_SECRET',
  DATABASE_URL = 'DATABASE_URL',
  GA_FTP_USERNAME = 'GA_FTP_USERNAME',
  GA_FTP_PASSWORD = 'GA_FTP_PASSWORD',
  CRB_FTP_USERNAME = 'CRB_FTP_USERNAME',
  CRB_FTP_PASSWORD = 'CRB_FTP_PASSWORD',
  FORBRIGHT_FTP_USERNAME = 'FORBRIGHT_FTP_USERNAME',
  FORBRIGHT_FTP_PASSWORD = 'FORBRIGHT_FTP_PASSWORD',
  SSO_CLIENT_ID = 'SSO_CLIENT_ID',
  SSO_CLIENT_SECRET = 'SSO_CLIENT_SECRET',
  SSO_TENANT_ID = 'SSO_TENANT_ID',
  POSTGRES_USERNAME = 'POSTGRES_USERNAME',
  POSTGRES_PASSWORD = 'POSTGRES_PASSWORD',
  PAYNET_USER = 'PAYNET_USER',
  PAYNET_PASSWORD = 'PAYNET_PASSWORD',
  DNB_TOKEN = 'DNB_TOKEN',
  TOOLS_DB_URL = 'TOOLS_DB_URL',
  FLASH_SPREAD_TOKEN = 'FLASH_SPREAD_TOKEN',
  API_KEY = 'API_KEY',
  FORM_STACK_KEY = 'FORM_STACK_KEY',
  FORM_STACK_SECRET = 'FORM_STACK_SECRET',
  OPENAI_API_KEY = 'OPENAI_API_KEY',
  OPEN_EI_KEY = 'OPEN_EI_KEY',
  ACR_CALCULATOR_KEY = 'ACR_CALCULATOR_KEY',
  USPS_KEY = 'USPS_KEY',
  USPS_SECRET = 'USPS_SECRET',
  POWERLYTICS_TOKEN = 'POWERLYTICS_TOKEN',
}

export const secrets = new Map();

export const RailResultType: { [key: number]: string } = {
  0: 'NotAttempted',
  1: 'Requested',
  2: 'Success',
  3: 'RequestFailure',
  4: 'Rejected',
  5: 'Returned',
  6: 'Skipped',
  7: 'ValidationFail',
  8: 'CosAchPaymentRejected',
  9: 'CosAchPaymentCanceled',
  10: 'CosAchNocReceived',
  11: 'CosAchHoldEscalated',
  12: 'CosCoreTransactionCompleted',
  13: 'CosCoreTransactionRejected',
  15: 'CosXPaymentReceived',
  16: 'CosXPaymentCanceled',
  17: 'CosXPaymentRejected',
  18: 'CosXPaymentFailed',
  19: 'CosXPaymentCompleted',
  20: 'CosCoreTransactionCompletedReverse',
  24: 'CosXPaymentRejectedReverse',
  25: 'CosXPaymentFailedReverse',
  26: 'CosXPaymentCanceledReverse',
  27: 'CheckCashed',
  28: 'CheckPrinted',
  29: 'DigitalCheckPrinted',
  30: 'CheckMailed',
  31: 'Failed',
};

export const RailType: { [key: number]: string } = {
  4: 'RPPS',
  7: 'NF',
  8: 'COSACH',
  9: 'COSACHSD',
  10: 'COSXPay',
  20: 'AdjustmentAction',
  30: 'Check',
  40: 'RTP',
};

export enum RailStatus {
  READY = 'Ready',
  REQUESTED = 'Requested',
  SKIPPED = 'Skipped',
  SENT = 'Sent',
  FAILED = 'Failed',
  SUCCESS = 'Success',
  NOT_ATTEMPTED = 'NotAttempted',
  REQUEST_FAILURE = 'RequestFailure',
  REJECTED = 'Rejected',
  RETURNED = 'Returned',
  VALIDATION_FAIL = 'ValidationFail',
}

export enum WebHooks {
  LoanStatusUpdated = 'LoanStatusUpdated',
  ComplianceLoanFailed = 'ComplianceLoanFailed',
  RailUpdated = 'RailUpdated',
}

export const USER_COOKIE_KEY = 'user';
export const USER_ROLE_COOKIE_KEY = 'user_role';
export const USER_GROUP_COOKIE_KEY = 'user_group';
export const USER_NAME_COOKIE_KEY = 'name';

export const stateCodeToState = {
  AA: 'Armed Forces America',
  AE: 'Armed Forces Europe',
  AK: 'Alaska',
  AL: 'Alabama',
  AP: 'Armed Forces Pacific',
  AR: 'Arkansas',
  AS: 'American Samoa',
  AZ: 'Arizona',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DC: 'District of Columbia',
  DE: 'Delaware',
  FL: 'Florida',
  FM: 'Micronesia',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  IA: 'Iowa',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  MA: 'Massachusetts',
  MD: 'Maryland',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MI: 'Michigan',
  MN: 'Minnesota',
  MO: 'Missouri',
  MP: 'Northern Mariana Islands',
  MS: 'Mississippi',
  MT: 'Montana',
  NC: 'North Carolina',
  ND: 'North Dakota',
  NE: 'Nebraska',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NV: 'Nevada',
  NY: 'New York',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  PW: 'Palau',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VA: 'Virginia',
  VI: 'Virgin Islands',
  VT: 'Vermont',
  WA: 'Washington',
  WI: 'Wisconsin',
  WV: 'West Virginia',
  WY: 'Wyoming',
};

export const stateCode = {
  'ARMED FORCES AMERICA': 'AA',
  'ARMED FORCES EUROPE': 'AE',
  ALASKA: 'AK',
  ALABAMA: 'AL',
  'ARMED FORCES PACIFIC': 'AP',
  ARKANSAS: 'AR',
  'AMERICAN SAMOA': 'AS',
  ARIZONA: 'AZ',
  CALIFORNIA: 'CA',
  COLORADO: 'CO',
  CONNECTICUT: 'CT',
  'DISTRICT OF COLUMBIA': 'DC',
  DELAWARE: 'DE',
  FLORIDA: 'FL',
  MICRONESIA: 'FM',
  GEORGIA: 'GA',
  GUAM: 'GU',
  HAWAII: 'HI',
  IOWA: 'IA',
  IDAHO: 'ID',
  ILLINOIS: 'IL',
  INDIANA: 'IN',
  KANSAS: 'KS',
  KENTUCKY: 'KY',
  LOUISIANA: 'LA',
  MASSACHUSETTS: 'MA',
  MARYLAND: 'MD',
  MAINE: 'ME',
  'MARSHALL ISLANDS': 'MH',
  MICHIGAN: 'MI',
  MINNESOTA: 'MN',
  MISSOURI: 'MO',
  'NORTHERN MARIANA ISLANDS': 'MP',
  MISSISSIPPI: 'MS',
  MONTANA: 'MT',
  'NORTH CAROLINA': 'NC',
  'NORTH DAKOTA': 'ND',
  NEBRASKA: 'NE',
  'NEW HAMPSHIRE': 'NH',
  'NEW JERSEY': 'NJ',
  'NEW MEXICO': 'NM',
  NEVADA: 'NV',
  'NEW YORK': 'NY',
  OHIO: 'OH',
  OKLAHOMA: 'OK',
  OREGON: 'OR',
  PENNSYLVANIA: 'PA',
  'PUERTO RICO': 'PR',
  PALAU: 'PW',
  'RHODE ISLAND': 'RI',
  'SOUTH CAROLINA': 'SC',
  'SOUTH DAKOTA': 'SD',
  TENNESSEE: 'TN',
  TEXAS: 'TX',
  UTAH: 'UT',
  VIRGINIA: 'VA',
  'VIRGIN ISLANDS': 'VI',
  VERMONT: 'VT',
  WASHINGTON: 'WA',
  WISCONSIN: 'WI',
  'WEST VIRGINIA': 'WV',
  WYOMING: 'WY',
};

export enum ReportType {
  PAYNET = 'paynet',
  DNB = 'dnb',
  LEXIS = 'lexis',
  EQUIFAX = 'equifax',
  POWERLYTICS = 'powerlytics',
}

export enum ActionType {
  LOAN_SENT = 'LOAN_SENT',
  LOAN_UPDATED = 'LOAN_UPDATED',
  LOAN_FILES_ATTACHED = 'LOAN_FILES_ATTACHED',
  LOAN_CANCELLED = 'LOAN_CANCELED',
  LOC_SENT = 'LOC_SENT',
  LOC_CANCELLED = 'LOC_CANCELLED',
  LOC_FILES_ATTACHED = 'LOC_FILES_ATTACHED',
  LOC_UPDATED = 'LOC_UPDATED',
  RAILS_SENT = 'RAILS_SENT',
  SFTP_FILE_UPLOADED = 'SFTP_FILE_UPLOADED',
  SFTP_FILE_DELETED = 'SFTP_FILE_DELETED',
  SFTP_FILE_DOWNLOADED = 'SFTP_FILE_DOWNLOADED',
  CHECKLIST_NOTE_EDITED = 'CHECKLIST_NOTE_EDITED',
  CHECKLIST_UPDATED = 'CHECKLIST_UPDATED',
  CHECKLIST_DELETED = 'CHECKLIST_DELETED',
  SCORECARD_UPLOADED = 'SCORECARD_UPLOADED',
  INSTALLER_SCORECARD_UPLOADED = 'INSTALLER_SCORECARD_UPLOADED',
  UW_TOOL_UPLOADED = 'UW_TOOL_UPLOADED',
  UW_REPORT_PULLED = 'UW_REPORT_PULLED',
  FILE_MANAGEMENT_REQUIRED_DOC_CREATE = 'FILE_MANAGEMENT_REQUIRED_DOC_CREATE',
  FILE_MANAGEMENT_REQUIRED_DOC_UPDATED = 'FILE_MANAGEMENT_REQUIRED_DOC_UPDATED',
  FILE_MANAGEMENT_REQUIRED_DOC_UPLOAD = 'FILE_MANAGEMENT_REQUIRED_DOC_UPLOAD',
  FILE_MANAGEMENT_DOWNLOAD_ALL = 'FILE_MANAGEMENT_DOWNLOAD_ALL',
  FILE_MANAGEMENT_SOFT_DELETE_FILE = 'FILE_MANAGEMENT_SOFT_DELETE_FILE',
  CREDIT_MEMO_GENERATED = 'CREDIT_MEMO_GENERATED',
  ACR_CALCULATED = 'ACR_CALCULATED',
}

export enum DocumentApprovalStatus {
  SENT_UPON_REQUEST = 'Sent Upon Request',
  AWAITING_UPLOAD = 'Awaiting Upload',
  SIGNATURE_NEEDED = 'Signature Needed',
  WAITING_REVIEW = 'Waiting Review',
  ISSUES_IDENTIFIED = 'Issues Identified',
  APPROVED = 'Approved',
}

export const docTypes = {
  SOLAR_LOAN_PROPOSAL: 'solar-loan-proposal',
  UTILITY_BILLS: 'utility-bills',
  FINANCIAL_STATEMENTS_LAST_YEAR: 'financial-statements-last-year',
  TAX_RETURNS_LAST_YEAR: 'tax-returns-last-year',
  TAX_RETURNS_2_YEARS_AGO: 'tax-returns-2-years-ago',
  TAX_RETURNS_3_YEARS_AGO: 'tax-returns-3-years-ago',
  FINANCIAL_STATEMENTS_CURRENT_YEAR: 'financial-statements-current-year',
};

export const BankLoanActions = {
  CANCELLED: 'CANCELLED',
  CREATED: 'CREATED',
  UPDATED: 'UPDATED',
  FILES_ATTACHED: 'FILES_ATTACHED',
  RAILS_SENT: 'RAILS_SENT',
};

export enum ScorecardFormulaInputKeys {
  // Debt to Income
  ANNUAL_GROSS_INCOME = 'annual-gross-income',
  TOTAL_MONTHLY_PAYMENTS = 'total-monthly-payments',
  POWERLYTICS_MIN = 'powerlytics-min',
  POWERLYTICS_MAX = 'powerlytics-max',
  BRACKETED_ANNUAL_GROSS_INCOME = 'bracketed-annual-gross-income',

  // Cash Coverage of Payments
  AVERAGE_CASH_BALANCE = 'avg-cash',
  FIRST_YEAR_LOAN_PAYMENT = 'first-year-solar-loan-payment',

  // All in 20 year System Benefits to Expense Ratio
  UTILITY_COST_SAVINGS = 'utility-cost-savings',
  SURPLUS_POWER_MONETIZATION = 'surplus-power-monetization',
  INVESTMENT_TAX_CREDIT = 'investment-tax-credit',
  TAX_DEPRECIATION_SAVINGS = 'tax-depreciation-savings',
  REC_PRODUCTION_INCENTIVES = 'rec-production-incentives',
  OTHER_INCENTIVES = 'other-incentives',
  MAINTENANCE_INSURANCE_AND_LEASES = 'maintenance-insurance-and-leases',
  TOTAL_LOAN_PAYMENTS = 'total-loan-payments',

  // Business DSCR
  EBITDA_FOR_BUSINESS_DSCR = 'ebitda-for-business-dscr',
  YEAR_ONE_SAVINGS_FOR_BUSINESS_DSCR = 'year-one-savings-for-business-dscr',
  REC_INCENTIVES_FOR_BUSINESS_DSCR = 'rec-incentives-for-business-dscr',
  BUSINESS_DEBT_OBLIGATIONS_FOR_DSCR = 'business-debt-obligations-for-business-dscr',
  ANNUAL_SOLAR_PAYMENT_FOR_BUSINESS_DSCR = 'annual-solar-payment-for-business-dscr',

  // Personal Liquidity
  REVOLVING_BALANCES = 'revolving-balances',
  INSTALLMENT_LOAN_TOTAL = 'installment-loan-total',
  LIQUID_ASSETS = 'liquid-assets',
  MARKETABLE_SECURITIES_BONDS = 'marketable-securities-bonds',
  LOAN_AMOUNT_FOR_PERSONAL_LIQUIDITY = 'loan-amount-for-personal-liquidity',

  // Property Value
  PROPERTY_VALUE = 'property-value',
  MORTGAGE_BALANCE = 'mortgage-balance',
}

export enum ScorecardItemKeys {
  LOAN_AMOUNT = 'loan-amount',
  LONGEVITY_OF_BUSINESS = 'longevity-of-business',
  OWNER_EXPERIENCE = 'owner-experience',
  PROPERTY_VALUE = 'property-value',
  EBITDA = 'ebitda',
  EBITDA_TREND = 'ebitda-trend',
  FCCR = 'fccr',
  FCCR_AVERAGE = 'fccr-average',
  DSCR_AVG = 'dscr-avg',
  DSCR_MIN = 'dscr-min',
  HIGH_RISK_BUSINESS = 'high-risk-business',
  PROPERTY_SYSTEM_RATIO = 'property-system-ratio',
  BANKRUPTCIES = 'bankruptcies',
  LIENS = 'liens',
  BUSINESS_VERIFICATION = 'business-verification',
  BUSINESS_VERIFICATION_SOS = 'business-verification-sos',
  PROPERTY_VERIFICATION = 'property-verification',
  CHECKING_ACCOUNT_VERIFICATION = 'checking-account-verification',
  BUSINESS_TO_DEBT_RATIO = 'business-to-debt-service-ratio',
  SYSTEM_BENEFIT_TO_EXPENSE_RATIO = 'system-benefit-to-expense-ratio',
  FICO_SCORE = 'fico-score',
  DEBT_TO_INCOME = 'debt-to-income',
  NAICS_ATTRACTIVENESS = 'naics-attractiveness',
  PROPERTY_TO_LOAN_VALUE = 'property-to-loan-value',
  CASH_COVERAGE_OF_PAYMENTS = 'cash-coverage-of-payments',
  EMPLOYMENT = 'employment',
  PAYMENTS_TO_SOLAR_DEBT_SERVICE_RATIO = 'recurring-incentive-payments-to-solar-debt-service-ratio',
  LIQUIDITY_TO_SOLAR_AMOUNT = 'personal-liquidity-to-solar-amount',
  PAYDEX_SCORE = 'paydex-score',
  BUSINESS_DSCR = 'business-dscr',
  POWERLYTICS = 'powerlytics',
}

export enum ScoreCardFormatters {
  CURRENCY = 'currency',
  DEFAULT = 'default',
  INTEGER = 'integer',
  PERCENT = 'percent',
  PERCENT_ONE_DECIMAL = 'percent-one-decimal',
  DEFAULT_STRING = 'default-string',
  VERIFICATION_BOOL = 'verification-bool',
  BANKRUPTCIES = 'bankruptcies',
  LIENS = 'liens',
  RISK = 'risk',
}

export enum ScoreCardEditComponents {
  EDIT_BOOL = 'edit-bool',
  EDIT_NUMERIC = 'edit-numeric',
}

export const QualityIndicators = {
  GOOD: 'Good',
  OK: 'Ok',
  BAD: 'Bad',
  NA: 'N/A',
};

export enum EntityRecord {
  ENTITY_RECORD_TYPE_ID_STAGING = '0127c000000DEe6AAG',
  INDIVIDUAL_RECORD_TYPE_ID_STAGING = '0127c000000DEe7AAG',
  ENTITY_RECORD_TYPE_ID_PROD = '0124X000001uHLQQA2',
  INDIVIDUAL_RECORD_TYPE_ID_PROD = '0124X000001uHLRQA2',
}

export enum OracleInsightType {
  SolarProposal = 'SolarProposal',
  LeaseAgreement = 'LeaseAgreement',
  PowerPurchaseAgreement = 'PowerPurchaseAgreement',
  UtilityBillValidation = 'UtilityBillValidation',
  CostarReport = 'CostarReport',
  TaxReturnValidation = 'TaxReturnValidation',
}

// note: duplicated in /client/src/components/ProjectChecklist/constants.ts
export const ChecklistItemType = {
  DOCUMENT_REQUIREMENT: 'DOCUMENT_REQUIREMENT',
  CALL: 'CALL',
  DOCUSIGN: 'DOCUSIGN',
};

// note: duplicated in /client/src/components/ProjectChecklist/constants.ts
export const ChecklistItemRule = {
  EXIST: 'EXIST',
  EQUAL: 'EQUAL',
  YES_NO: 'YES_NO',
};

export const UserGroups = {
  INSTALLER: 'INSTALLER',
  CONSULTANT: 'CONSULTANT',
  INSTALLER_AND_CONSULTANT: 'INSTALLER_AND_CONSULTANT',
  BORROWER: 'BORROWER',
  ALL: 'ALL',
};

export const NEW_VERSION_ALERT_DAYS = 14;

export const ChecklistNewVersionCheckFields = {
  Loan_Amount__c: 'Loan Amount',
  Loan_Product__c: 'Loan Product',
};

// map Railway Uploaded Documents statuses to Sunstone Document approval statuses
export const approvalStatusMap = {
  VERIFIED: 'Verified',
  NEEDS_REVIEW: 'Waiting Review',
  ISSUES_IDENTIFIED: 'Issues Identified',
  MISSING: 'Missing',
};
